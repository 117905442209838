@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:wght@200;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }

  /* input:required:invalid {
        border-color: red;
      } */
}





@keyframes appearFromLeft {
  from {
    opacity: 0;
    transform: translateX(-500%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes appearFromRight {
  from {
    opacity: 0;
    transform: translateX(500%) skewX(150deg) skewY(-50deg);
  }
  to {
    opacity: 1;
    transform: translateX(0%) skewX(0deg) skewY(-0deg);
  }
}
@keyframes appearFromDown {
  from {
    opacity: 0;
    transform: translateY(500%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
/* 
.animaiteLeft {
  animation: appearFromLeft 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 50%;
}

.animaiteRight {
  animation: appearFromRight ease-in-out;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
  animation-iteration-count: 1;
}

.animaiteDown {
  animation: appearFromDown ease-in-out;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
  animation-iteration-count: 1;
} */
